import React from "react";
import {Switch, Route} from "react-router-dom";
import PublicRoute from "../components/auth/PublicRoute";
import AppProvider from "./../store/index";
import SideMenu from "../components/side-menu";
import PrivateRoute from "../components/auth/PrivateRoute";
import SuspenseFallback from "./../components/suspense/index";
import Modal from "./../components/alerts/modal/index";
import {
  NotFound,
  ReviewOrderSections,
  Login,
  ReviewOrders,
  Dashboard,
  RootLogout,
  DealTracker,
  OperationsReports,
} from "../code-splitting";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShowFile from "../components/alerts/image-modal";
import { Redirect } from "react-router-dom";
import { useBoxConnect } from "../hooks/useBoxConnect";
import OperationsReportsIframe from "../components/operations-reports/OperationsReportIframe";

function Home() {
  const [{ isBoxConnect }] = useBoxConnect();

  return (
    <AppProvider>
      <Modal />
      <ShowFile />
      <ToastContainer
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <section className="" style={{height: "80vh"}}>
        <section className="d-flex">
          <SideMenu isBoxConnect={isBoxConnect} />
          <React.Suspense fallback={<SuspenseFallback />}>
            <Switch>
              <PublicRoute path="/login" component={Login} exact={true} />
              <PrivateRoute
                path="/"
                component={() => <Redirect to="/deals" />}
                exact={true}
              />
              <PrivateRoute
                path="/logout"
                component={RootLogout}
                exact={true}
              />
              <PrivateRoute
                path="/dashboard"
                component={Dashboard}
                exact={true}
              />
              <PrivateRoute
                path="/deals"
                component={ReviewOrders}
                exact={true}
              />
              <PrivateRoute
                path="/deal-tracker/:id"
                component={DealTracker}
                exact={true}
              />
              <PrivateRoute
                path="/deals/:id"
                component={ReviewOrderSections}
                exact={true}
              />
              <PrivateRoute
                path="/operations-reports"
                component={OperationsReports}
                exact={true}
              />

              <PrivateRoute
                path="/operations-reports/:id"
                component={OperationsReportsIframe}
                exact={true}
              />
              <Route component={NotFound} />
            </Switch>
          </React.Suspense>
        </section>
      </section>
    </AppProvider>
  );
}

export default React.memo(Home);
